export const FieldConstants = {
	TIMEDURATION: "TimeDuration",
	HOURS: "Hours",
	MINUTES: "Minutes",
	CBRAUTOAPPROVAL: "SendCBRToMSFlag",
	HARDWAREINFORMATION: "UpdateCBRFlag",
	AUTORETURN: "AutoReturnFlag",
	AUTOSUBMITCBR: "AutoSubmitCBRFlag",
	ISAUTOSUBMITCBR: "IsAutoSubmitCbr",
	TIMEDURATIONDROPDOWN: "TimeDurationDropdown",
	TIME: "SettingTime",
	TIMEHOURSDROPDOWN: "TimeHoursDropdown",
	TIMEMINUTESDROPDOWN: "TimeMinutesDropdown",
	SAVECHANGESBTN: "saveChangesBtn"
};

export const MSARetiralFieldConstants = {
	EXISTING_CREDENTIALS: "existingCredentials",
	AAD_LOGIN_CREDENTIALS: "aadLoginCredentails",
	FULL_TICKET_DATA: "fillticketdata"
};

export const MSARetiralFIELDLENGTHS = {
	EXISTING_CREDENTIALS: 100,
	AAD_LOGIN_CREDENTIALS: 100
};

export const Message = {
	SUCCESSMESSGAE: "Details updated successfully!"
};

export const AutoSubmitCBRValues = {
	AUTOSUBMITCBRUNCHECKED: "0",
	TIMEDURATION: "1",
	TIME: "2"
};

export const TimeDurations = [
	{ key: "0.5", text: "30 Minute" },
	{ key: "1", text: "1 Hour" },
	{ key: "2", text: "2 Hour" },
	{ key: "4", text: "4 Hours" },
	{ key: "6", text: "6 Hour" },
	{ key: "12", text: "12 Hour" },
	{ key: "24", text: "24 Hour" }
];

export const Hour = [
	{ key: "00", text: "00" },
	{ key: "01", text: "01" },
	{ key: "02", text: "02" },
	{ key: "03", text: "03" },
	{ key: "04", text: "04" },
	{ key: "05", text: "05" },
	{ key: "06", text: "06" },
	{ key: "07", text: "07" },
	{ key: "08", text: "08" },
	{ key: "09", text: "09" },
	{ key: "10", text: "10" },
	{ key: "11", text: "11" },
	{ key: "12", text: "12" },
	{ key: "13", text: "13" },
	{ key: "14", text: "14" },
	{ key: "15", text: "15" },
	{ key: "16", text: "16" },
	{ key: "17", text: "17" },
	{ key: "18", text: "18" },
	{ key: "19", text: "19" },
	{ key: "20", text: "20" },
	{ key: "21", text: "21" },
	{ key: "22", text: "22" },
	{ key: "23", text: "23" }
];

export const Minute = [
	{ key: "00", text: "00" },
	{ key: "30", text: "30" }
];
