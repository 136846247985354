export class BusinessFlagsEntity {
	constructor(saveSettingData) {
		this.SendCBRToMSFlag = saveSettingData.SendCBRToMSFlag;
		this.UpdateCBRFlag = saveSettingData.UpdateCBRFlag;
		this.AutoReturnFlag = saveSettingData.AutoReturnFlag;
		this.IsAutoSubmitCbr = saveSettingData.IsAutoSubmitCbr;
		this.CbrSubmitTime = saveSettingData.CbrSubmitTime;
		this.TimeDuration = saveSettingData.TimeDuration;
	}
}
